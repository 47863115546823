import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { SupportEmailLink } from "./lithic/Links";

export function LegalPage({ title, children }) {
  return (
    <Container component="main" maxWidth="md">
      <Box mt={3}>
        <Typography paragraph variant="h2" component="h2">
          {title}
        </Typography>
        {children}
      </Box>
    </Container>
  );
}

export function LegalHead({ children }) {
  return (
    <Typography paragraph variant="h4" component="h4">
      {children}
    </Typography>
  );
}

export function LegalPara({ children }) {
  return <Typography paragraph>{children}</Typography>;
}

export function LegalContact({ docname, date }) {
  return (
    <React.Fragment>
      <LegalHead>Contact Us</LegalHead>
      <LegalPara>We welcome your questions or comments regarding {docname}:</LegalPara>
      <LegalPara>
        Lithic Technology
        <br />
        5305 River Rd North
        <br />
        STE B<br />
        Keizer, OR 97303
        <br />
        Email Address: <SupportEmailLink />
        <br />
      </LegalPara>
      <LegalPara>Effective as of {date}</LegalPara>
    </React.Fragment>
  );
}
