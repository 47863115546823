import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { SupportEmailLink, TwitterHandleLink } from "./lithic/Links";

const useStyles = makeStyles((theme) => ({
  colorList: {
    textAlign: "center",
    fontWeight: "bold",
  },
  colorListItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function Faq() {
  const classes = useStyles();
  function colorListItem(color, name) {
    return (
      <div
        className={classes.colorListItem}
        style={{ backgroundColor: color, color: "white" }}
      >
        {name}
      </div>
    );
  }
  return (
    <Box mt={3}>
      <Typography component="h3" variant="h3" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Topic>
        <Question>Why did you build CopyCat?</Question>
        <Paragraph>
          Existing solutions around "keeping your Google calendars in sync" are just how
          to share your Google calendar, so one account can view and modify the calendar
          for other accounts.
        </Paragraph>
        <Paragraph>
          This doesn't solve the problem of actual synchronization of multiple calendars
          for a person &mdash; that is, they need multiple calendars to reflect the
          free/busy state of them as a singular human being.
        </Paragraph>
        <Paragraph>
          CopyCat actually copies events between your primary calendars of multiple Google
          accounts, so you have a single free/busy status and can see all your events from
          any single calendar.
        </Paragraph>
      </Topic>
      <Topic>
        <Question>How much does it cost?</Question>
        <Paragraph>
          CopyCat is free for now, but we may charge a small ($1) fee per-calendar in the
          future.
        </Paragraph>
        <Paragraph>
          CopyCat is built by <Link href="https://lithic.tech">Lithic Technology</Link>, a
          software development agency. If you want to support us, you or your company
          should hire us!
        </Paragraph>
      </Topic>
      <Topic>
        <Question>Why do my calendar colors not match?</Question>
        <Paragraph>
          Google Calendar's calendars and events use different sets of color options, so
          if you have a calendar set to a color that does not have a matching event color,
          CopyCat does its best to find a match. For best results, we suggest you use
          calendar colors that are also present as event colors. That list is:
        </Paragraph>
        <div className={classes.colorList}>
          {colorListItem("#D50000", "Tomato")}
          {colorListItem("#E67C73", "Flamingo")}
          {colorListItem("#F4511E", "Tangerine")}
          {colorListItem("#F6BF26", "Banana")}
          {colorListItem("#33B679", "Sage")}
          {colorListItem("#0B8043", "Basil")}
          {colorListItem("#039BE5", "Peacock")}
          {colorListItem("#3F51B5", "Blueberry")}
          {colorListItem("#7986CB", "Lavender")}
          {colorListItem("#8E24AA", "Grape")}
          {colorListItem("#616161", "Graphite")}
        </div>
      </Topic>
      <Topic>
        <Question>Meow?</Question>
        <Paragraph>
          If you have any other questions, please email us <SupportEmailLink /> or on
          Twitter <TwitterHandleLink />.
        </Paragraph>
      </Topic>
    </Box>
  );
}

function Topic({ children }) {
  return <Box mt={2}>{children}</Box>;
}

function Question({ children }) {
  return (
    <Typography variant="h5" component="h5">
      {children}
    </Typography>
  );
}

function Paragraph({ children }) {
  return (
    <Typography variant="body2" paragraph>
      {children}
    </Typography>
  );
}
