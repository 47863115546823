export default class OutdatedBrowser {
  outdated() {
    const ua =
      typeof window !== "undefined" && window.navigator && window.navigator.userAgent;
    if (!ua) {
      return true;
    } else if (ua.indexOf("MSIE ") > 0) {
      return true;
    } else if (ua.indexOf("Trident/") > 0) {
      return true;
    }
    return false;
  }
}
