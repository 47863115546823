import { createMuiTheme } from "@material-ui/core/styles";

// Taken from @material-ui/core/es/styles/createTypography.js
const defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
const serifFontFamily = '"Rokkitt", ' + defaultFontFamily;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#52154E",
    },
    secondary: {
      main: "#669176",
    },
    error: {
      main: "#FF1717",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    h1: { fontFamily: serifFontFamily, fontSize: "4.5rem" },
    h2: { fontFamily: serifFontFamily, fontSize: "3.5rem" },
    h3: { fontFamily: serifFontFamily, fontSize: "2.5rem" },
    h4: { fontFamily: serifFontFamily },
    h5: { fontFamily: serifFontFamily },
    h6: { fontFamily: serifFontFamily },
  },
});

export default theme;
