import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React from "react";

import Faq from "../Faq";
import LinkAccountButton from "../LinkAccountButton";
import LinkedAccountList from "../LinkedAccountList";
import { injectUser } from "../lithic/UserContext";
import signOut from "../signOut";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

function SignIn(props) {
  return (
    <Container component="main" maxWidth="sm">
      <Box mt={3}>
        <Typography paragraph>
          CopyCat automatically synchronizes Google Calendar events between multiple
          accounts, so every account reflects your free/busy status and you can see your
          entire agenda everywhere.
        </Typography>
        <Typography paragraph>
          No more sharing calendars or inviting your personal email &mdash; CopyCat
          handles it all for you!
        </Typography>
        {props.user.id ? <Authed user={props.user} /> : <Unauthed />}
        <Box mt={3}>
          <Divider />
        </Box>
        <HowTo />
        <Divider />
        <Faq />
      </Box>
    </Container>
  );
}

export default injectUser()(SignIn);

function Authed({ user }) {
  return (
    <Box mt={3}>
      <Typography component="h2" variant="h2" paragraph>
        Welcome back {_.first((user.defaultName || "").split(" "))}!
      </Typography>
      {user.linkedAccounts.length === 1 ? (
        <OneAccountLinked user={user} />
      ) : (
        <MultipleAccountsLinked user={user} />
      )}
      <LinkAccountButton />
      <Box mt={2}>
        <Button fullWidth color="secondary" size="small" onClick={signOut}>
          Sign Out
        </Button>
      </Box>
    </Box>
  );
}

function OneAccountLinked({ user }) {
  return (
    <Box>
      <Typography variant="body2" paragraph>
        Link another Google account and CopyCat will start synchronizing events between
        their calendars.
      </Typography>
      <Box mb={2}>
        <LinkedAccountList linkedAccounts={user.linkedAccounts} />
      </Box>
    </Box>
  );
}

function MultipleAccountsLinked({ user }) {
  return (
    <Box>
      <Typography variant="body2" paragraph>
        CopyCat is cross-syncing {user.linkedAccounts.length} calendars.
      </Typography>
      <Box mb={2}>
        <LinkedAccountList linkedAccounts={user.linkedAccounts} />
      </Box>
      <Typography variant="body2" paragraph>
        You can link more accounts, and CopyCat will cross-sync all events between them.
      </Typography>
    </Box>
  );
}

function Unauthed() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FakeSigninButton text="Register with Google" />
      </Grid>
      <Grid item xs={12} md={6}>
        <FakeSigninButton text="Log In with Google" variant="outlined" />
      </Grid>
    </Grid>
  );
}

function FakeSigninButton({ text, variant }) {
  return (
    <LinkAccountButton
      variant={variant}
      render={(svc) => (
        <React.Fragment>
          <Icon>
            <img src={svc.imgSrc} alt={svc.name} />
          </Icon>
          &nbsp;&nbsp;
          {text}
        </React.Fragment>
      )}
    />
  );
}

function HowTo() {
  const classes = useStyles();
  return (
    <Box mt={3}>
      <Typography component="h3" variant="h3" gutterBottom>
        How to Use CopyCat
      </Typography>
      <ol className={classes.list}>
        <li>
          Add all the Google accounts you want to cross-sync at{" "}
          <Link href="https://copycat.events">copycat.events</Link>.
        </li>
        <li>
          When you add or edit an event, you should do it on the &lsquo;right&rsquo;
          calendar. Add work appointments on your work calendar, personal ones on your
          personal calendar, etc.
        </li>
        <li>
          Changes are automatically copied to all of your linked calendar accounts, with
          color coding so the &lsquo;source&rsquo; of the event is easy to identify. Only
          you can see the details of the copied events, though &mdash; so even if you have
          a public work calendar, coworkers will not be able to see details of your
          personal calendar events, just that you are busy.
        </li>
        <li>
          If you are currently sharing calendars between multiple accounts in Google, you
          can hide all but one. Normally you'll keep your personal calendar (which now
          reflects all events) visible, and hide the others.
        </li>
      </ol>
    </Box>
  );
}
