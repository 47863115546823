import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router";

import api from "./api";
import { locationAsNext } from "./followNext";
import { localStorageCache } from "./localStorageHelper";
import { withSentry } from "./sentry";

export const UserContext = React.createContext({});

export class UserProvider extends React.Component {
  constructor(props) {
    super(props);
    const initialUser = _.assign({ unset: true }, this.props.defaultUser);
    this.state = {
      user: initialUser,
      adminUser: initialUser,
      setUser: (user) => {
        this.setState({ user: user });
        this.afterUserSet(user, this.state.adminUser);
        localStorageCache.setItem("user", user);
        return user;
      },
      setAdminUser: (user) => {
        this.setState({ adminUser: user });
        this.afterUserSet(this.state.user, user);
        return user;
      },
    };
  }

  afterUserSet(user, admin) {
    this.setSentryUser(this.props.normalizeUser(user), this.props.normalizeUser(admin));
    this.props.onUserSet(user, admin);
  }

  setSentryUser(user, admin) {
    withSentry((Sentry) => {
      // noinspection JSUnresolvedFunction
      Sentry.configureScope((scope) => {
        const fields = {
          id: user.id,
          email: user.email,
          name: user.name,
          admin_id: admin.id,
          admin_email: admin.email,
        };
        scope.setUser(_.pickBy(fields, _.identity));
        // noinspection JSUnresolvedFunction
        scope.setTag("user.email", admin.email || user.email);
      });
    });
  }

  findOrGetUser() {
    const cachedUser = localStorageCache.getItem("user");
    const valid = cachedUser && _.isArray(cachedUser.roles);
    if (valid) {
      this.state.setUser(cachedUser);
    }
    return this.props.fetchCurrentUser();
  }

  componentDidMount() {
    return this.findOrGetUser()
      .then(api.pickData)
      .tapCatch(localStorageCache.removeItem("user"))
      .then(this.state.setUser)
      .then((user) => {
        if (user.sudoed) {
          return this.props
            .fetchAdminUser()
            .then(api.pickData)
            .then(this.state.setAdminUser);
        }
      })
      .catch(() => {
        this.state.setUser(this.props.defaultUser);
        this.state.setAdminUser(this.props.defaultUser);
      });
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.defaultProps = {
  onUserSet: _.noop,
  normalizeUser: _.partialRight(_.pick, ["id", "name", "email"]),
};

UserProvider.propTypes = {
  defaultUser: PropTypes.object.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  fetchAdminUser: PropTypes.func.isRequired,
  onUserSet: PropTypes.func,
  /**
   * Function that takes a user object and returns {id, name, email}.
   */
  normalizeUser: PropTypes.func,
};

export const injectUser = () => {
  return (Wrapped) => {
    return (props) => {
      return (
        <UserContext.Consumer>
          {(context) => <Wrapped {...props} {...context} />}
        </UserContext.Consumer>
      );
    };
  };
};

export const redirectUnless = (to, appendNext, test) => {
  return () => {
    return (Wrapped) => {
      return (props) => {
        let realTo = to;
        if (appendNext) {
          realTo = locationAsNext(window.location, to);
        }
        return (
          <UserContext.Consumer>
            {(context) =>
              test(context) ? <Wrapped {...props} /> : <Redirect to={realTo} />
            }
          </UserContext.Consumer>
        );
      };
    };
  };
};

// If the user agent is prerender, we generally do not want to redirect,
// since we want to avoid rendering the login page as a 'default' card.
export const isPrerenderUA = () =>
  _.includes(_.get(window, "navigator.userAgent", ""), "prerender");

export const hasRole = (user, role) => {
  return _.includes(user.roles, role);
};

export const redirectIfNotAdmin = redirectUnless(
  "/admin/forbidden",
  false,
  function isAdmin(context) {
    return (
      context.user.unset ||
      context.adminUser.unset ||
      context.user.sudoed ||
      context.adminUser.id ||
      isPrerenderUA()
    );
  }
);
