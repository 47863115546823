import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

export default function makeColorButton(color, options) {
  options = options || {};
  let baseColor, hoverColor;
  if (color.main) {
    baseColor = color.main;
    hoverColor = color.light;
  } else if (color[500]) {
    baseColor = color[options.hue || 500];
    hoverColor = color[options.hoverHue || 700];
  } else {
    console.error("invalid color for makeColorButton:", color);
    return Button;
  }

  const ColorButton = withStyles((theme) => {
    return {
      root: {
        color: theme.palette.getContrastText(baseColor),
        backgroundColor: baseColor,
        "&:hover": {
          backgroundColor: hoverColor,
        },
      },
    };
  })(Button);
  return ColorButton;
}
