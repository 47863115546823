import api from "./api";
import { localStorageCache } from "./lithic/localStorageHelper";
import refreshAsync from "./lithic/refreshAsync";

export default function signOut() {
  api
    .signOut()
    .then(() => localStorageCache.clear())
    .then(refreshAsync);
}
