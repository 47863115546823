import React from "react";

/**
 * Handles external dependent loading for React components that need external JS libs
 * (Google maps/places, Stripe).
 *
 * See https://www.fullstackreact.com/articles/Declaratively_loading_JS_libraries/index.html for inspiraiton.
 */
export class ScriptLoader {
  constructor() {
    this.loadSrcs = this.loadSrcs.bind(this);
    this.loadSrc = this.loadSrc.bind(this);
    this.storeGlobal = this.storeGlobal.bind(this);
    this.fetchGlobal = this.fetchGlobal.bind(this);
    this.promises = {};
    this.globals = {};
  }

  storeGlobal(key, value) {
    this.globals[key] = value;
  }

  fetchGlobal(key) {
    return this.globals[key];
  }

  loadSrcs = (srcs) => {
    const promises = srcs.map((src) => this.loadSrc(src));
    return Promise.all(promises).tapCatch((error) => {
      console.log("Sources", srcs, "failed to load:", error);
    });
  };

  loadSrc(src) {
    const existing = this.promises[src];
    if (existing) {
      return existing;
    }
    const pending = this.scriptTag(src);
    this.promises[src] = pending;
    return pending;
  }

  scriptTag(src) {
    return new Promise((resolve, reject) => {
      let resolved = false;
      let errored = false;

      const handleLoad = () => {
        resolved = true;
        resolve(src);
      };
      const handleReject = () => {
        errored = true;
        reject(src);
      };

      const tag = document.createElement("script");
      tag.type = "text/javascript";
      tag.async = false; // Load in order
      tag.addEventListener("load", handleLoad);
      tag.addEventListener("error", handleReject);
      tag.onreadystatechange = function() {
        if (resolved) {
          return handleLoad();
        }
        if (errored) {
          return handleReject();
        }
        const state = tag.readyState;
        if (state === "complete") {
          handleLoad();
        } else if (state === "error") {
          handleReject();
        }
      };
      tag.src = src;

      const body = document.getElementsByTagName("body")[0];
      body.appendChild(tag);
      return tag;
    });
  }
}

export const ScriptLoaderContext = React.createContext({});

export class ScriptLoaderProvider extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new ScriptLoader();
    this.state = {
      loadSrc: this.loader.loadSrc,
      loadSrcs: this.loader.loadSrcs,
      storeGlobal: this.loader.storeGlobal,
      fetchGlobal: this.loader.fetchGlobal,
    };
  }

  render() {
    return (
      <ScriptLoaderContext.Provider value={this.state}>
        {this.props.children}
      </ScriptLoaderContext.Provider>
    );
  }
}

/**
 * loadScript is a HOC that provides an 'onScriptLoad' prop that will fire the callback
 * when the given scripts are loaded.
 *
 * Since this is used for loading external scripts, they should be available
 * as window globals (window.Stripe, window.google).
 *
 * In case the loaded library requires further initialization (like the window.Stripe function
 * must be initialized with an API key), components wrapped with loadScripts can
 * use storeGlobal inside the onScriptLoad callback to store the initialized global,
 * and then fetchGlobal later to retrieve the initialized global.
 */
export const loadScripts = (...srcs) => {
  return (Wrapped) => {
    return (props) => {
      return (
        <ScriptLoaderContext.Consumer>
          {(context) => (
            <Wrapped
              {...props}
              onScriptLoad={(cb) => context.loadSrcs(srcs).then(cb)}
              storeGlobal={context.storeGlobal}
              fetchGlobal={context.fetchGlobal}
            />
          )}
        </ScriptLoaderContext.Consumer>
      );
    };
  };
};
