import * as Sentry from "@sentry/browser";
import _ from "lodash";

/**
 * Synchronously invoke a callback with the Sentry object,
 * swallowing and logging any errors.
 *
 * This is done to avoid errors while doing stuff with Sentry themselves causing errors
 * (either due to programming bugs, Sentry not loading, etc).
 */
export const withSentry = (cb) => {
  try {
    cb(Sentry);
  } catch (e) {
    console.error("Error during Sentry call:", e);
  }
};

export const configure = ({
  dsn,
  environment,
  release,
  releaseContext,
  outdatedBrowser,
}) => {
  withSentry((Sentry) => {
    Sentry.init({
      dsn: dsn,
      environment: environment,
      release: release,
      beforeSend: (event, context) => {
        if (outdatedBrowser.outdated()) {
          console.log("Outdated browser errored:", event);
          return null;
        }
        const status = _.get(context, "originalException.response.status");
        if (status === 401 || status === 404) {
          return null;
        }
        return event;
      },
    });
    Sentry.configureScope((scope) => {
      _.forOwn(_.pickBy(releaseContext), (v, k) => scope.setTag(k, v));
    });
  });
};
