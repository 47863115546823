const makeCache = (store) => {
  return {
    /**
     * Return parsed JSON from local storage,
     * stored under the given key.
     * Return defaultValue on failure (not present, parse failure, etc).
     *
     * NOTE: The parsed value may be missing fields you expect,
     * either due to versioning issues, or browser stuff.
     * So always check or assign default values of complex objects.
     */
    getItem: (field, defaultValue = {}) => {
      try {
        const cachedJSON = store.getItem(field);
        if (!cachedJSON) {
          return defaultValue;
        }
        return JSON.parse(cachedJSON);
      } catch (err) {
        console.log("Get cache error: ", err);
        return defaultValue;
      }
    },
    setItem: (field, item) => {
      try {
        const itemJSON = JSON.stringify(item);
        store.setItem(field, itemJSON);
      } catch (err) {
        console.log("Set cache error: ", err);
      }
    },
    removeItem: (field) => {
      try {
        store.removeItem(field);
      } catch (err) {
        console.log("Remove cache error:", err);
      }
    },
    clear: () => {
      try {
        store.clear();
      } catch (err) {
        console.log("Clear cache error:", err);
      }
    },
  };
};

export const localStorageCache = makeCache(window.localStorage);
export const sessionStorageCache = makeCache(window.sessionStorage);
