import _ from "lodash";
import React from "react";

/**
 * cancelOnUnmount is a higher-order component that will cancel pending promises on unmount.
 * Call `props.cancelOnUnmount(promise)` on the wrapped component.
 * The promise will be uniquely identified, and will be canceled when the HOC is unmounted.
 */
const cancelOnUnmount = () => {
  return (Wrapped) => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.cancelOnUnmount = this.cancelOnUnmount.bind(this);
        this.lastId = 10;
        this.idPool = _.range(0, this.lastId);
        this.promisesForIds = {};
      }

      cancelOnUnmount(cancellable) {
        const id = this.borrowId();
        let entry = cancellable;
        if (cancellable.then) {
          const returnId = () => {
            this.returnId(id);
          };
          entry = cancellable.tap(returnId).tapCatch(returnId);
        }
        this.promisesForIds[id] = entry;
        return entry;
      }

      borrowId() {
        if (this.idPool.length === 0) {
          this.lastId += 1;
          this.idPool.push(this.lastId);
        }
        return this.idPool.pop();
      }

      returnId(id) {
        this.promisesForIds[id] = undefined;
        this.idPool.push(id);
      }

      componentWillUnmount() {
        _.forOwn(this.promisesForIds, (p, id) => {
          if (p) {
            p.cancel();
            this.returnId(id);
          }
        });
      }

      render() {
        return <Wrapped {...this.props} cancelOnUnmount={this.cancelOnUnmount} />;
      }
    };
  };
};

export default cancelOnUnmount;
