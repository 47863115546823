import _ from "lodash";

import queryString from "./queryString";

export const everyAncestor = (node, predicate) => {
  let parent = node && node.parentNode;
  while (parent) {
    const matched = predicate(parent);
    if (!matched) {
      return false;
    }
    parent = parent.parentNode;
  }
  return true;
};

export const someAncestor = (node, predicate) => {
  let parent = node && node.parentNode;
  while (parent) {
    const matched = predicate(parent);
    if (matched) {
      return true;
    }
    parent = parent.parentNode;
  }
  return false;
};

export const appendIfUnique = (enumerable, value, predicate) => {
  if (predicate && _.isString(predicate)) {
    predicate = _.matchesProperty(predicate, _.get(value, predicate));
  }
  const idx = _.findIndex(enumerable, predicate || value);
  if (idx === -1) {
    return _.concat(enumerable, value);
  }
  return enumerable;
};

/**
 * Clamps a value between a minimum number and maximum number.
 * @param value
 * @param min
 * @param max
 * @returns {number}
 */
export const clamp = (value, min, max) => {
  return Math.max(min, Math.min(max, value));
};

/**
 * Linearly interpolates between v0 and v1,
 * so that when `term` is 0, `v0` is returned,
 * when `term` is 1, `v1` is returned,
 * and when `term` is .5, halfway between `v0` and `v1` is returned.
 * term is clamped to be between 0 and 1.
 */
export const lerp = (v0, v1, term) => {
  const clamped = clamp(term, 0, 1);
  return v0 + clamped * (v1 - v0);
};

export const consumeQuery = (location) => {
  if (location.params) {
    return location.params;
  }
  location.params = queryString.parse(location.search);
  return location.params;
};

export const replaceQueryParam = (location, key, value) => {
  return replaceQueryParams(location, { [key]: value });
};

export const replaceQueryParams = (location, replacements) => {
  const params = _.clone(consumeQuery(location));
  _.forOwn(replacements, (v, k) => {
    params[k] = v;
  });
  return `${location.pathname}?${queryString.stringify(params)}`;
};

export const tryAll = (defaultValue = null, ...callbacks) => {
  let result = defaultValue;
  _.find(callbacks, (cb) => {
    try {
      result = cb();
      return true;
    } catch (e) {
      return false;
    }
  });
  return result;
};

export default {
  everyAncestor,
  someAncestor,
  appendIfUnique,
  clamp,
  consumeQuery,
  lerp,
  replaceQueryParam,
  tryAll,
};
