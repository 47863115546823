import React from "react";

import cancelOnUnmount from "./cancelOnUnmount";

const injectAsyncData = (propKey, task, initialState) => {
  return (Wrapped) => {
    class InjectAsyncData extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          data: initialState,
          loading: true,
          error: false,
        };
      }

      componentDidMount() {
        this.setState({ loading: true, error: false });
        const p = task(this.props)
          .then((d) => {
            this.setState({ data: d, loading: false, error: null });
          })
          .catch((r) => {
            this.setState({ loading: false, error: r });
          });
        this.props.cancelOnUnmount(p);
      }

      render() {
        const props = {};
        props[propKey] = this.state.data;
        props[`${propKey}Error`] = this.state.error;
        props[`${propKey}Loading`] = this.state.loading;
        return <Wrapped {...this.props} {...props} />;
      }
    }
    return cancelOnUnmount()(InjectAsyncData);
  };
};

export default injectAsyncData;
