import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  noImageNoCalColor: {
    backgroundColor: red[500],
    width: 50,
    height: 50,
  },
  noImageWithCalColor: {
    backgroundColor: (p) => p.color,
    width: 50,
    height: 50,
  },
  imageNoCalColor: {
    width: 50,
    height: 50,
  },
  imageWithCalColor: {
    borderColor: (p) => p.color,
    borderWidth: 6,
    borderStyle: "solid",
    width: 50,
    height: 50,
  },
}));

/**
 * Render the avatar for the calendar. There are a few rules/things to know:
 * - If the linked account has a non-empty image, use that image.
 *   - Note: Google will use a letter on the user's background color as the image.
 *   - If the linked account has a calendar color, create a border with that color.
 * - If there is no image, use the user's first initial,
 *   and the linked account color as the background color.
 *   - If there is no linked account color, use red.
 */
export default function CalendarAvatar({ linkedAccount }) {
  const classes = useStyles({ color: linkedAccount.color });
  let key;
  if (linkedAccount.imageUrl && linkedAccount.color) {
    key = "imageWithCalColor";
  } else if (linkedAccount.imageUrl && !linkedAccount.color) {
    key = "imageNoCalColor";
  } else if (!linkedAccount.imageUrl && linkedAccount.color) {
    key = "noImageWithCalColor";
  } else {
    key = "noImageNoCalColor";
  }
  return (
    <Avatar
      aria-label="recipe"
      src={linkedAccount.imageUrl}
      alt={linkedAccount.name}
      className={classes[key]}
    >
      {linkedAccount.name[0].toUpperCase()}
    </Avatar>
  );
}
