import React from "react";

export default function inSuspense(fallback = <div>Loading...</div>) {
  return (Wrapped) => {
    return (props) => {
      return (
        <React.Suspense fallback={fallback}>
          <Wrapped {...props} />
        </React.Suspense>
      );
    };
  };
}
