import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import api from "./api";
import googleLogo from "./images/Google__G__Logo.svg";
import injectAsyncData from "./lithic/injectAsyncData";

const useStyles = makeStyles(() => ({}));

function LinkAccountButton({ oauthServices, render, variant }) {
  const classes = useStyles();
  if (oauthServices.length === 0) {
    return <CircularProgress className={classes.progress} />;
  }

  return oauthServices.map((svc) => {
    const provider = PROVIDER_MAP[svc.provider];

    return (
      <Button
        key={svc.provider}
        href={svc.url}
        fullWidth
        variant={variant || "contained"}
        color={PROVIDER_MAP[svc.provider].color}
        size="large"
        className={classes.submit}
      >
        {render ? render(provider) : `Link ${provider.name} Account`}
      </Button>
    );
  });
}

export default injectAsyncData(
  "oauthServices",
  () =>
    api
      .getOauthServices()
      .then(api.pickData)
      .then(api.pick("items")),
  []
)(LinkAccountButton);

const PROVIDER_MAP = {
  google: { color: "primary", name: "Google", imgSrc: googleLogo },
};
