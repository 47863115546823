import axios from "axios";
import humps from "humps";
import _ from "lodash";

const create = (apiHost) => {
  return axios.create({
    baseURL: apiHost,
    timeout: 60000,
    withCredentials: true,
    transformResponse: [
      ...axios.defaults.transformResponse,
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest,
    ],
  });
};

const pick = (s) => {
  return (o) => o[s];
};
const pickData = pick("data");
const log = (res) => console.log(res);

const swallow = (status) => {
  return (error) => {
    if (_.get(error, "response.data.error.status") === status) {
      return;
    }
    throw error;
  };
};

/**
 * setState is a helper for the common case of setting the state from an API response,
 * instead of having a bespoke closure.
 *
 * @param receiver Usually 'this' in the caller.
 * @param field The name of the field in the state to set.
 *   If '.', use setState(data).
 * @param path Optional. The path in the data response to pull out.
 *   Usually this is 'items' for a collection response.
 */
const setState = (receiver, field, path) => {
  return (responseData) => {
    const data = path ? _.get(responseData, path) : responseData;
    if (field === ".") {
      receiver.setState(data);
    } else {
      receiver.setState({ [field]: data });
    }
    return responseData;
  };
};

export default {
  create,
  pick,
  pickData,
  log,
  swallow,
  setState,
};
