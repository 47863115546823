import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import api from "./api";
import config from "./config";
import Footer from "./Footer";
import Header from "./Header";
import applyHocs from "./lithic/applyHocs";
import Promise from "./lithic/bluejay";
import { PageContentBoundary, TopLevelBoundary } from "./lithic/ErrorBoundaries";
import inSuspense from "./lithic/inSuspense";
import { SupportEmailLink } from "./lithic/Links";
import OutdatedBrowser from "./lithic/OutdatedBrowser";
import { ScriptLoaderProvider } from "./lithic/scriptLoader";
import { configure as configureSentry } from "./lithic/sentry";
import { UserProvider, redirectIfNotAdmin } from "./lithic/UserContext";
import HomePage from "./Pages/HomePage";
import PreauthPage from "./Pages/PreauthPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

configureSentry({
  dsn: config.sentryDSN,
  environment: config.environment,
  release: config.commitRef,
  releaseContext: {
    branch: config.branch,
    rootUrl: config.rootUrl,
    pullRequestId: config.pullRequestId,
  },
  outdatedBrowser: new OutdatedBrowser(),
});

const AdminIndex = React.lazy(() => import("./Admin/Pages/AdminIndex"));
const AdminMessages = React.lazy(() => import("./Admin/Pages/AdminMessages"));
const AdminMessageDetail = React.lazy(() => import("./Admin/Pages/AdminMessageDetail"));
const AdminMessagePreview = React.lazy(() => import("./Admin/Pages/AdminMessagePreview"));
const AdminUsers = React.lazy(() => import("./Admin/Pages/AdminUsers"));
const AdminUserDetail = React.lazy(() => import("./Admin/Pages/AdminUserDetail"));

export default class App extends React.Component {
  /**
   * Return the Navbar component to use, based on the current route.
   * This duplicates information that should probably be declarative in
   * the routes in the normal switch, but this is good enough for now
   * as we probably don't need a ton of granularity in the routes.
   */
  static navSwitch() {
    return (
      <Switch>
        <Route component={Header} />
      </Switch>
    );
  }

  static footerSwitch() {
    return (
      <Switch>
        <Route path="/admin" component={null} />
        <Route component={Footer} />
      </Switch>
    );
  }

  static switch() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/preauth" component={PreauthPage} />

        {this.adminRoute("", AdminIndex)}
        {this.adminRoute("/users", AdminUsers)}
        {this.adminRoute("/user/:userId", AdminUserDetail)}
        {this.adminRoute("/messages", AdminMessages)}
        {this.adminRoute("/message/:messageId", AdminMessageDetail)}
        {this.adminRoute("/message-preview/:templateClass", AdminMessagePreview)}

        <Route render={() => <Redirect to="/" push />} />
      </Switch>
    );
  }

  static adminRoute(tail, component) {
    return (
      <Route
        exact
        path={`/admin${tail}`}
        component={applyHocs(inSuspense(), redirectIfNotAdmin(), component)}
      />
    );
  }

  render() {
    return (
      <TopLevelBoundary>
        <Router>
          <ScriptLoaderProvider>
            <div className="App d-flex flex-column">
              <UserProvider
                defaultUser={{ linkedAccounts: [], roles: [] }}
                fetchCurrentUser={api.getCurrentUser}
                fetchAdminUser={() => null}
                normalizeUser={(u) => ({
                  id: u.id,
                  name: u.defaultName,
                  email: u.defaultEmail,
                })}
              >
                {App.navSwitch()}
                <PageContentBoundary onError={() => <FiveHundred />}>
                  {App.switch()}
                </PageContentBoundary>
                {App.footerSwitch()}
              </UserProvider>
            </div>
          </ScriptLoaderProvider>
        </Router>
      </TopLevelBoundary>
    );
  }
}

function FiveHundred() {
  return (
    <div style={{ margin: "10px", marginBottom: "60px", textAlign: "center" }}>
      <h4>Hmmm, something isn&rsquo;t quite right here.</h4>
      <p>
        We&rsquo;re really sorry about that. Our engineers have been notified. You can try{" "}
        <a href={window.location.href} onClick={() => window.location.reload()}>
          refreshing the page
        </a>
        .
      </p>
      <p>
        If the problem persists, please email us at <SupportEmailLink />
        and we&rsquo;ll get it all sorted out.
      </p>
    </div>
  );
}
