import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

dayjs.extend(advancedFormat);

export const Time = (props) => {
  if (!props.children) {
    return null;
  }
  const t = dayjs(props.children).format(props.format);
  return <time>{t}</time>;
};

Time.propTypes = {
  children: PropTypes.string,
  format: PropTypes.string.isRequired,
};

export const Day = (props) => {
  return <Time format="MM/DD/YYYY">{props.children}</Time>;
};

export const MonthYear = (props) => {
  return <Time format="MMMM, YYYY">{props.children}</Time>;
};

export const HumanTime = (props) => {
  return <Time format="MM/DD/YYYY HH:mm:ss">{props.children}</Time>;
};

export const durationName = (minutes) => {
  const minutePart = minutes % 60;
  const hourPart = Math.floor(minutes / 60);
  if (minutePart === 0 && hourPart === 0) {
    return "0 minutes";
  }
  const parts = [null, null];
  if (hourPart === 1) {
    parts[0] = `1 hour`;
  } else if (hourPart > 1) {
    parts[0] = `${hourPart} hours`;
  }
  if (minutePart === 1) {
    parts[1] = `1 minute`;
  } else if (minutePart > 1) {
    parts[1] = `${minutePart} minutes`;
  }

  return _.compact(parts).join(", ");
};

export const durationClock = (minutes) => {
  const minutePart = minutes % 60;
  const hourPart = Math.floor(minutes / 60);
  return _.padStart(hourPart, 2, "0") + ":" + _.padStart(minutePart, 2, "0");
};

export const WDAY_TO_NAME = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};
