const config = {
  // environment can be used for for behavior switching.
  // permissible: [development, test, staging, production]
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  commitRef: (process.env.REACT_APP_COMMIT_REF || "HEAD").slice(0, 7),
  branch: process.env.REACT_APP_BRANCH,
  rootUrl: process.env.REACT_APP_ROOT_URL,
  pullRequestId: process.env.REACT_APP_PULL_REQUEST_ID,
  extendedAttrs: process.env.REACT_APP_EXTENDED_ATTRS,
  calsyncApiHost: process.env.REACT_APP_CALSYNC_API_HOST,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default config;
