import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { LegalContact, LegalHead, LegalPara } from "../legal";
import { SupportEmailLink } from "../lithic/Links";

export default function PrivacyPolicy() {
  return (
    <Container component="main" maxWidth="md">
      <Box mt={3}>
        <Typography paragraph variant="h2" component="h2">
          Privacy Policy
        </Typography>

        <LegalPara>
          Here&rsquo;s the short of it: we keep your data as safe as we can, store and
          track as little as we must, and share with 3rd parties only what is necessary.
          We do not store the content of any of your calendar events.
        </LegalPara>
        <LegalPara>
          The rest of this document is the long of it. When this document uses "we", it
          means <Link href="https://lithic.tech">Lithic Technology LLC</Link>, the
          creators and operators of CopyCat (this website).
        </LegalPara>
        <LegalPara>
          By visiting this website, you are accepting the terms of this Privacy Policy.
        </LegalPara>

        <LegalHead>Information We Gather or Receive</LegalHead>
        <LegalPara>
          For each Google account you link to CopyCat, Google sends CopyCat an access
          token. We use this access token to fetch the name and email address associated
          with that Google account when you link an account. The access token, and the
          Google account&rsquo;s name, email address, and calendar color are stored in our
          database.
        </LegalPara>
        <LegalPara>
          CopyCat uses the access tokens from each of your linked Google accounts to
          periodically fetch calendar events from your Google accounts and synchronize
          them with your other calendars. CopyCat does not store the content of any of
          your calendar events.
        </LegalPara>
        <LegalPara>
          If you want to remove CopyCat&rsquo;s access to a linked Google account, you can
          Unlink the account from CopyCat, or revoke the access token in your Google
          Account. When you Unlink a Google account in CopyCat, the access token is
          removed and CopyCat cannot access that account in the future.
        </LegalPara>
        <LegalPara>
          We will not sell or disclose your name, email address or other personal
          information to third parties without your explicit consent, except as specified
          in this policy.
        </LegalPara>
        <LegalPara>
          CopyCat automatically receives and records some information from your browser
          when you visit https://copycat.events, such as your IP address, browser version,
          and which pages you visit. This information helps us analyze and understand how
          CopyCat works for customers and visitors.
        </LegalPara>

        <LegalHead>Controlling Your Information</LegalHead>
        <LegalPara>
          When you Unlink an account, CopyCat deletes the access token associated with
          that account. Without the access token, we cannot access your Google account or
          calendar.
        </LegalPara>
        <LegalPara>
          We may contact you about our services or your activity. Some of these messages
          are required, transaction-related messages to customers. Any messages that are
          not required will contain unsubscribe links.
        </LegalPara>
        <LegalPara>
          If you no longer wish to use CopyCat or receive service-related messages, you
          can unlink all accounts, or contact support at <SupportEmailLink />.
        </LegalPara>

        <LegalHead>What Information We Share</LegalHead>
        <LegalPara>
          We will never sell or disclose any of your personal information to third parties
          without your explicit consent, except as specified in this policy.
        </LegalPara>
        <LegalPara>
          We may release your personal information to a third-party in order to comply
          with a subpoena or other legal requirement, or when we believe in good faith
          that such disclosure is necessary to comply with the law; prevent imminent
          physical harm or financial loss; or investigate or take action regarding illegal
          activities, suspected fraud, or violations of CopyCat&rsquo;s Terms &
          Conditions. We may disclose personally identifiable information to parties in
          compliance with our Copyright Policy, as we in our sole discretion believe
          necessary or appropriate in connection with an investigation of fraud,
          intellectual property infringement, piracy, or other unlawful activity.
        </LegalPara>
        <LegalPara>
          When we use other companies and people to perform tasks on our behalf, we may
          need to share your information with them to provide products and services to
          you. An example would include, but is not limited to, processing payments.
        </LegalPara>

        <LegalHead>Cookies & Tracking Technologies</LegalHead>
        <LegalPara>
          CopyCat does not use cookie-based tracking technologies or services. We only
          collect basic anonymous usage statistics, and use private cookies for
          authentication.
        </LegalPara>

        <LegalHead>Data Retention</LegalHead>
        <LegalPara>
          CopyCat will retain your information only for as long your account is active or
          as needed to provide you services. If you no longer want CopyCat to use your
          information to provide you services, you should Unlink all your accounts. When
          you Unlink an account in CopyCat, CopyCat deletes any CopyCat-created events
          from that account&rsquo;s calendar and deletes any CopyCat-created events
          originating from that calendar that exist on your other linked calendars. Your
          name and email address are retained for diagnostic purposes for 30 days after
          you Unlink an account.
        </LegalPara>

        <LegalHead>Privacy Policy Changes</LegalHead>
        <LegalPara>
          CopyCat reserves the right to modify this privacy statement at any time.
          We&rsquo;ll communicate changes by posting a notice on this page
          (https://copycat.events/privacy). If we make material changes to this policy you
          will be notified here, by email, or other places CopyCat deems appropriate.
        </LegalPara>

        <LegalContact docname="this Privacy Policy" date="September 2, 2019" />
      </Box>
    </Container>
  );
}
