import PropTypes from "prop-types";
import React from "react";

import { SupportEmailLink } from "./Links";
import { withSentry } from "./sentry";

const configureAndCapture = (Sentry, error, errorInfo) => {
  Sentry.configureScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
  });
  Sentry.captureException(error);
};

export class TopLevelBoundary extends React.Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    withSentry((Sentry) => {
      configureAndCapture(Sentry, error, errorInfo);
    });
  }

  render() {
    if (this.state.error) {
      withSentry((Sentry) => {
        Sentry.showReportDialog();
      });
      return (
        <div style={{ margin: "40px", textAlign: "center" }}>
          <h3>
            We&rsquo;re really sorry about that. Thanks for submitting the bug report.
          </h3>
          <h3>
            You can try refreshing your browser to see if the problem has cleared up, or
            email us at <SupportEmailLink /> and we&rsquo;ll get it all sorted out.
          </h3>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export class PageContentBoundary extends React.Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    withSentry((Sentry) => {
      configureAndCapture(Sentry, error, errorInfo);
    });
  }

  render() {
    if (this.state.error) {
      return this.props.onError();
    } else {
      return this.props.children;
    }
  }
}

PageContentBoundary.propTypes = {
  onError: PropTypes.func.isRequired,
};
