import React from "react";

import api from "../api";
import applyHocs from "../lithic/applyHocs";
import { followNext } from "../lithic/followNext";
import { consumeQuery } from "../lithic/lorun";
import { injectUser } from "../lithic/UserContext";

function PreauthPage(props) {
  React.useEffect(() => {
    const params = consumeQuery(props.location);
    api
      .authTestUser({ email: params.email })
      .then(api.pickData)
      .then((resp) => props.setUser(resp))
      .tap(() => followNext(props))
      .tapCatch(api.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div />;
}

export default applyHocs(injectUser())(PreauthPage);
