import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import _ from "lodash";
import React from "react";

import api from "./api";
import CalendarAvatar from "./CalendarAvatar";
import cancelOnUnmount from "./lithic/cancelOnUnmount";
import { SafeExternalLink } from "./lithic/Links";
import { Day } from "./lithic/time";
import { injectUser } from "./lithic/UserContext";
import makeColorButton from "./makeColorButton";
import signOut from "./signOut";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  defaultEmail: {
    fontWeight: "bold",
    fontSize: "120%",
  },
  email: {
    fontSize: "120%",
  },
  subheader: {
    lineHeight: `${theme.typography.fontSize * 1.8}px`,
  },
}));

const BUTTON_FEEDBACK_DELAY = 1500;

function LinkedAccount({ linkedAccount, user, setUser }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function updateLinkedAccounts(apiFunc) {
    return () =>
      apiFunc({ id: linkedAccount.id })
        .delayOr(BUTTON_FEEDBACK_DELAY)
        .then(api.pickData)
        .then(({ user }) => {
          // This must be the final link in the chain, because setUser will trigger a render
          // and potentially break any handler that runs after it from running.
          if (_.isEmpty(user.linkedAccounts)) {
            signOut();
          } else {
            setUser(user);
          }
        });
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<CalendarAvatar linkedAccount={linkedAccount} />}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={
          <span className={linkedAccount.default ? classes.defaultEmail : classes.email}>
            {linkedAccount.email}
          </span>
        }
        subheader={
          <span className={classes.subheader}>
            {linkedAccount.default && "Primary Account"}
            {linkedAccount.default && <br />}
            Added <Day>{linkedAccount.createdAt}</Day>
          </span>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <MakeDefaultAction
            linkedAccount={linkedAccount}
            user={user}
            onClick={updateLinkedAccounts(api.linkedAccountMakeDefault)}
          />
          <ReauthAction linkedAccount={linkedAccount} />
          <UnlinkAction
            linkedAccount={linkedAccount}
            user={user}
            onClick={updateLinkedAccounts(api.linkedAccountUnlink)}
          />
          <AccountAction last>
            <ActionText>
              If this calendar is looking messed up for some reason, you can clear all
              Sync events from it and it will be re-synchronized.
            </ActionText>
            <ActionButton
              text="Clear Sync Events"
              onClick={() =>
                api
                  .linkedAccountResync({ id: linkedAccount.id })
                  .delayOr(BUTTON_FEEDBACK_DELAY)
              }
            />
          </AccountAction>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default injectUser()(LinkedAccount);

function MakeDefaultAction({ linkedAccount, user, onClick }) {
  const theme = useTheme();
  const content = (
    <React.Fragment>
      <ActionTextParagraph paragraph>
        {user.defaultEmail} is currently your Primary Account.
      </ActionTextParagraph>
      <ActionTextParagraph paragraph>
        Your Primary Account has full visibility into events on all other calendars.{" "}
        <strong>We suggest you use your personal email</strong>, since any GSuite Admins
        have full visibility into all events in their org, even private ones. See{" "}
        <SafeExternalLink href="https://support.google.com/a/answer/1084780">
          this Google Support article
        </SafeExternalLink>{" "}
        for more information.
      </ActionTextParagraph>
      <ActionTextParagraph>
        You can generally view only your Primary Account, and hide other accounts where
        you use a Calendar, like on your phone.
      </ActionTextParagraph>
      <ActionTextParagraph>
        We also send CopyCat service emails to your Primary Account.
      </ActionTextParagraph>
    </React.Fragment>
  );
  if (linkedAccount.default) {
    return (
      <AccountAction>
        <ActionTextContainer>{content}</ActionTextContainer>
      </AccountAction>
    );
  }
  return (
    <AccountAction>
      <ActionTextContainer>{content}</ActionTextContainer>
      <ActionButton
        text="Make Primary Account"
        onClick={onClick}
        color={theme.palette.error}
      />
      <ActionTextContainer>
        <ActionTextParagraph>
          Before making <strong>{linkedAccount.email} your Primary Account</strong>,
          please read our warning above!
        </ActionTextParagraph>
      </ActionTextContainer>
    </AccountAction>
  );
}

/**
 * @return {null|React.ReactElement}
 */
function ReauthAction({ linkedAccount }) {
  if (!linkedAccount.reauthorizationUrl) {
    return null;
  }
  return (
    <AccountAction>
      <ActionText>
        There was a problem accessing your calendar. Please Reauthorize our access so we
        can keep syncing.
      </ActionText>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          href={linkedAccount.reauthorizationUrl}
        >
          Reauthorize
        </Button>
      </Grid>
    </AccountAction>
  );
}

function UnlinkAction({ user, onClick }) {
  const theme = useTheme();
  if (user.linkedAccounts.length === 1) {
    return (
      <AccountAction>
        <ActionText>
          Unlinking this account will <strong>close your CopyCat account</strong> and
          delete all your information out of our system. If you decide to rejoin, you can
          re-link your accounts.
        </ActionText>
        <ActionButton
          text="Unlink & Close Account"
          onClick={onClick}
          color={theme.palette.error}
        />
      </AccountAction>
    );
  }
  return (
    <AccountAction>
      <ActionText>
        Unlinking this account will remove all Sync events from it, remove all Sync events
        sourced from it on other calendars, stop including it in any synchronizations.
      </ActionText>
      <ActionButton text="Unlink" onClick={onClick} />
    </AccountAction>
  );
}

function AccountAction({ children, last }) {
  return (
    <Grid container spacing={2}>
      {children}
      {last ? null : (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
}

const useActionButtonStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function _ActionButton({ text, onClick, cancelOnUnmount, color }) {
  const theme = useTheme();

  const classes = useActionButtonStyles();
  const [loading, setLoading] = React.useState(false);
  const [, setSuccess] = React.useState(false);

  const ColorButton = makeColorButton(color || theme.palette.secondary);

  function handleButtonClick() {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      cancelOnUnmount(
        onClick().tapTap(() => {
          setSuccess(true);
          setLoading(false);
        })
      );
    }
  }

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <ColorButton
            variant="contained"
            size="small"
            disabled={loading}
            onClick={handleButtonClick}
          >
            {text}
          </ColorButton>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    </Grid>
  );
}
const ActionButton = cancelOnUnmount()(_ActionButton);

function ActionText({ children }) {
  return (
    <ActionTextContainer>
      <ActionTextParagraph>{children}</ActionTextParagraph>
    </ActionTextContainer>
  );
}

function ActionTextContainer({ children }) {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
}

function ActionTextParagraph({ paragraph, children }) {
  return (
    <Typography variant="body2" paragraph={paragraph}>
      {children}
    </Typography>
  );
}
