import _ from "lodash";

import config from "./config";
import api from "./lithic/api";

const instance = api.create(config.calsyncApiHost);

const post = (path, opts) => {
  return instance.post(path, opts);
};

const put = (path, opts) => {
  return instance.put(path, opts);
};

const get = (path, opts) => {
  return instance.get(path, opts);
};

const del = (path, opts) => {
  return instance.delete(path, opts);
};

const moreApi = _.merge({}, api, {
  get: get,
  post: post,
  put: put,
  delete: del,

  signOut: () => del("/v1/auth"),
  getCurrentUser: (opts) => get("/v1/user", opts),
  authTestUser: (opts) => post(`/v1/auth/test_user`, opts),

  getOauthServices: (opts) => get("/v1/auth/oauth_services", opts),

  linkedAccountMakeDefault: (opts) =>
    post(`/v1/linked_accounts/${opts.id}/default`, opts),
  linkedAccountUnlink: (opts) => del(`/v1/linked_accounts/${opts.id}`, opts),
  linkedAccountResync: (opts) => post(`/v1/linked_accounts/${opts.id}/resync`, opts),

  admin: {
    impersonate: (opts) => post(`/admin/v1/auth/impersonate/${opts.id}`, opts),
    unimpersonate: (opts) => del(`/admin/v1/auth/impersonate`, opts),
    getUsers: (opts) => get(`/admin/v1/users`, opts),
    getUser: (opts) => get(`/admin/v1/users/${opts.id}`, opts),
    getMessageDeliveries: (opts) => get(`/admin/v1/message_deliveries`, opts),
    getMessageDelivery: (opts) => get(`/admin/v1/message_deliveries/${opts.id}`, opts),
  },
});

export default moreApi;
