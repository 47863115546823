import React from "react";

import { LegalContact, LegalHead, LegalPage, LegalPara } from "../legal";
import { SupportEmailLink } from "../lithic/Links";

function TermsAndConditions() {
  return (
    <LegalPage title="Terms & Conditions">
      <LegalPara>
        Okay, here’s the lawyer-y bit. Hopefully this is intelligible to the average bear
        — but if you have any questions, concerns, or suggestions, please reach out to{" "}
        <SupportEmailLink />.
      </LegalPara>
      <LegalHead>Agreement Between User and Lithic Technology LLC</LegalHead>
      <LegalPara>
        The copycat.events website (the "Site") is comprised of various web pages operated
        by Lithic Technology LLC (“Lithic Technology”) and is offered to you conditioned
        on your acceptance without modification of the terms, conditions, and notices
        contained herein (the "Terms"). Your use of the Site constitutes your agreement to
        all such Terms. Please read these terms carefully, and keep a copy of them for
        your reference.
      </LegalPara>
      <LegalHead>Electronic Communications</LegalHead>
      <LegalPara>
        Visiting the Site or sending emails to Lithic Technology constitutes electronic
        communications. You consent to receive electronic communications and you agree
        that all agreements, notices, disclosures and other communications that Lithic
        Technology provides to you electronically, via email and on the Site, satisfy any
        legal requirement that such communications be in writing.
      </LegalPara>
      <LegalHead>Your Account</LegalHead>
      <LegalPara>
        If you use this Site, you are responsible for maintaining the confidentiality of
        your account, linked accounts, and any passwords and for restricting access to
        your computer, and you agree to accept responsibility for all activities that
        occur under your account. You may not assign or otherwise transfer your accounts
        to any other person or entity. You acknowledge that Lithic Technology is not
        responsible for third party access to your account that results from theft or
        misappropriation of your account. Lithic Technology and its associates reserve the
        right to refuse or cancel service, terminate accounts, or remove or edit content
        in our sole discretion.
      </LegalPara>
      <LegalHead>Assignment</LegalHead>
      <LegalPara>
        You may not assign or transfer these Terms, by operation of law or otherwise,
        without Lithic Technology’s prior written consent. Any attempt by you to assign or
        transfer these Terms, without such consent, will be null and of no effect. Lithic
        Technology may assign or transfer these Terms, at its sole discretion, without
        restriction. Subject to the foregoing, these Terms will bind and inure to the
        benefit of the parties, their successors and permitted assigns.
      </LegalPara>
      <LegalPara>
        Lithic Technology does not knowingly collect, either online or offline, personal
        information from persons under the age of 13. If you are under the age of 18, you
        may use the Site only with permission of a parent or guardian.
      </LegalPara>
      <LegalHead>Links to Third Party Sites/Services</LegalHead>
      <LegalPara>
        The Site may contain links to other websites ("Linked Sites"). The Linked Sites
        are not under the control of Lithic Technology and Lithic Technology is not
        responsible for the contents of any Linked Site, including without limitation any
        link contained in a Linked Site, or any changes or updates to a Linked Site.
        Lithic Technology is providing these links to you only as a convenience, and the
        inclusion of any link does not imply endorsement by Lithic Technology of the
        Linked Site or any association with its operators.
      </LegalPara>
      <LegalPara>
        Certain services made available via the Site are delivered by third party sites
        and organizations. By using any product, service or functionality originating from
        the copycat.events domain, you hereby acknowledge and consent that Lithic
        Technology may share such information and data with any third party with whom
        Lithic Technology has a contractual relationship to provide the requested product,
        service or functionality on behalf of the Site's users and customers.
      </LegalPara>
      <LegalHead>No Unlawful or Prohibited Use/Intellectual Property</LegalHead>
      <LegalPara>
        You are granted a non-exclusive, non-transferable, revocable license to access and
        use the Site strictly in accordance with these terms of use. As a condition of
        your use of the Site, you warrant to Lithic Technology that you will not use the
        Site for any purpose that is unlawful or prohibited by these Terms. You may not
        use the Site in any manner which could damage, disable, overburden, or impair the
        Site or interfere with any other party's use and enjoyment of the Site. You may
        not obtain or attempt to obtain any materials or information through any means not
        intentionally made available or provided for through the Site.
      </LegalPara>
      <LegalPara>
        All content included as part of the Service, such as text, graphics, logos,
        images, as well as the compilation thereof, and any software used on the Site, is
        the property of Lithic Technology or its suppliers and protected by copyright and
        other laws that protect intellectual property and proprietary rights. You agree to
        observe and abide by all copyright and other proprietary notices, legends or other
        restrictions contained in any such content and will not make any changes thereto.
      </LegalPara>
      <LegalPara>
        You will not modify, publish, transmit, reverse engineer, participate in the
        transfer or sale, create derivative works, or in any way exploit any of the
        content, in whole or in part, found on the Site without prior written permission
        from Lithic Technology. Products available on Lithic Technology are not intended
        for resale. Your use of the Site does not entitle you to make any unauthorized use
        of any protected content, and in particular you will not delete or alter any
        proprietary rights or attribution notices in any content. You will use protected
        content solely for your personal use, and will make no other use of the content
        without the express written permission of Lithic Technology and the copyright
        owner. You agree that you do not acquire any ownership rights in any protected
        content. Lithic Technology does not grant you any licenses, express or implied, to
        the intellectual property of Lithic Technology or our licensors except as
        expressly authorized by these Terms.
      </LegalPara>
      <LegalHead>International Users</LegalHead>
      <LegalPara>
        The Service is controlled, operated and administered by Lithic Technology from our
        offices within the USA. If you access the Service from a location outside the USA,
        you are responsible for compliance with all local laws. You agree that you will
        not use the Lithic Technology Content accessed through the Site in any country or
        in any manner prohibited by any applicable laws, restrictions or regulations.
      </LegalPara>
      <LegalHead>Indemnification</LegalHead>
      <LegalPara>
        You agree to indemnify, defend and hold harmless Lithic Technology, its officers,
        directors, employees, agents and third parties, for any losses, costs, liabilities
        and expenses (including reasonable attorneys' fees) relating to or arising out of
        your use of or inability to use the Site or services, any user postings made by
        you, your violation of any terms of this Agreement or your violation of any rights
        of a third party, or your violation of any applicable laws, rules or regulations.
        Lithic Technology reserves the right, at its own cost, to assume the exclusive
        defense and control of any matter otherwise subject to indemnification by you, in
        which event you will fully cooperate with Lithic Technology in asserting any
        available defenses.
      </LegalPara>
      <LegalHead>Liability Disclaimer</LegalHead>
      <LegalPara>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH
        THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
        PERIODICALLY ADDED TO THE INFORMATION HEREIN. LITHIC TECHNOLOGY AND/OR ITS
        SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
      </LegalPara>
      <LegalPara>
        LITHIC TECHNOLOGY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
        SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE
        SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
        IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. LITHIC TECHNOLOGY AND/OR ITS
        SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
        IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE AND NON-INFRINGEMENT.
      </LegalPara>
      <LegalPara>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LITHIC
        TECHNOLOGY AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
        WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN
        ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR
        INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
        PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE
        SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
        EVEN IF LITHIC TECHNOLOGY OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
        POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
        ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF
        THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
        DISCONTINUE USING THE SITE.
      </LegalPara>
      <LegalHead>Termination/Access Restriction</LegalHead>
      <LegalPara>
        Lithic Technology reserves the right, in its sole discretion, to terminate your
        access to the Site and the related services or any portion thereof at any time,
        without notice.
      </LegalPara>
      <LegalHead>Miscellaneous</LegalHead>
      <LegalPara>
        You agree that no joint venture, partnership, employment, or agency relationship
        exists between you and Lithic Technology as a result of this agreement or use of
        the Site. Lithic Technology's performance of this agreement is subject to existing
        laws and legal process, and nothing contained in this agreement is in derogation
        of Lithic Technology's right to comply with governmental, court and law
        enforcement requests or requirements relating to your use of the Site or
        information provided to or gathered by Lithic Technology with respect to such use.
        If any part of this agreement is determined to be invalid or unenforceable
        pursuant to applicable law including, but not limited to, the warranty disclaimers
        and liability limitations set forth above, then the invalid or unenforceable
        provision will be deemed superseded by a valid, enforceable provision that most
        closely matches the intent of the original provision and the remainder of the
        agreement shall continue in effect.
      </LegalPara>
      <LegalPara>
        Unless otherwise specified herein, this agreement constitutes the entire agreement
        between the user and Lithic Technology with respect to the Site and it supersedes
        all prior or contemporaneous communications and proposals, whether electronic,
        oral or written, between the user and Lithic Technology with respect to the Site.
        A printed version of this agreement and of any notice given in electronic form
        shall be admissible in judicial or administrative proceedings based upon or
        relating to this agreement to the same extent and subject to the same conditions
        as other business documents and records originally generated and maintained in
        printed form. It is the express wish to the parties that this agreement and all
        related documents be written in English.
      </LegalPara>
      <LegalHead>Changes to Terms</LegalHead>
      <LegalPara>
        Lithic Technology reserves the right, in its sole discretion, to change the Terms
        under which the Site is offered. The most current version of the Terms will
        supersede all previous versions. Lithic Technology encourages you to periodically
        review the Terms to stay informed of our updates.
      </LegalPara>
      <LegalContact docname="these Terms" date="September 2, 2019" />
    </LegalPage>
  );
}

export default TermsAndConditions;
