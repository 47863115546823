import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";

import LinkedAccount from "./LinkedAccount";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

function LinkedAccountList({ linkedAccounts }) {
  const classes = useStyles();

  const defaultAccount = _.find(linkedAccounts, "default");
  const otherAccounts = _.reject(linkedAccounts, "default");

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {defaultAccount && (
          <Grid item xs={12}>
            <LinkedAccountContainer linkedAccount={defaultAccount} />
          </Grid>
        )}
        {otherAccounts.map((li) => (
          <Grid key={li.email} item xs={12}>
            <LinkedAccountContainer linkedAccount={li} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default LinkedAccountList;

function LinkedAccountContainer({ linkedAccount }) {
  return (
    <div>
      <Grow in>
        <Paper elevation={4}>
          <LinkedAccount linkedAccount={linkedAccount} />
        </Paper>
      </Grow>
    </div>
  );
}
