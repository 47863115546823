import queryString from "./queryString";

export const followNext = (routable) => {
  return followParam(routable, "next");
};

export const locationAsNext = (location, newUrl) => {
  return locationAsParam(location, "next", newUrl);
};

export const followParam = (routable, param) => {
  const params = queryString.parse(routable.location.search);
  const next = params[param];
  if (!next) {
    return false;
  }
  routable.history.push(next);
  return true;
};

export const locationAsParam = (location, param, newUrl) => {
  const existingUrl = location.pathname + location.search;
  const search = queryString.stringify({ [param]: existingUrl });
  return newUrl + "?" + search;
};
