import AppBar from "@material-ui/core/AppBar";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import logo from "./images/cat-duotone.svg";

const useStyles = makeStyles(() => ({
  title: {
    textAlign: "center",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Typography component="h1" variant="h1" className={classes.title}>
        <Link href="/" color="inherit" underline="none">
          Copy
          <Icon fontSize="large">
            <img src={logo} alt="CopyCat logo" />
          </Icon>
          Cat
          <Icon fontSize="large">
            <img src={logo} alt="CopyCat logo" />
          </Icon>
        </Link>
      </Typography>
    </AppBar>
  );
}
