import Link from "@material-ui/core/Link";
import React from "react";

export const SupportEmailLink = (props) => {
  const text = props.children || "copycat@lithic.tech";
  return <Link href="mailto:copycat@lithic.tech">{text}</Link>;
};

export const TwitterHandleLink = (props) => {
  const handle = props.handle || "lithictechpdx";
  const text = props.children ? props.children : `@${handle}`;
  return <Link href={`https://twitter.com/${handle}`}>{text}</Link>;
};

export const SafeExternalLink = (props) => {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      className={props.className}
      style={props.style}
    >
      {props.children}
    </a>
  );
};

/**
 * LinkIf renders children in a Link to href if href is truthy.
 * This is useful to creating a link if a URL is available,
 * or just using non-linked HTML if not.
 * Callers can explicitly supply 'cond' as a condition to check
 * in addition to href.
 */
export const LinkIf = ({ href, children, cond = true }) => {
  if (cond && href) {
    return <Link href={href}>{children}</Link>;
  }
  return children;
};
