import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <Box mb={4}>
      <Divider className={classes.divider} />
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://lithic.tech/">
          Lithic Technology LLC
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Box component="span">
          <Link href={"/terms"}>Terms & Conditions</Link>
        </Box>
        <Box component="span" ml={2}>
          <Link href={"/privacy"}>Privacy Policy</Link>
        </Box>
      </Typography>
    </Box>
  );
}

export default Footer;
